<template>
    <div :class="executionStatus">
        <dt class="flex items-center px-2 py-3 cursor-pointer hover:bg-neutral-100" @click="setCollapsed">
            <div
                class="flex items-center justify-between w-full text-left focus:outline-none"
                aria-controls="faq-0"
                aria-expanded="false"
            >
                <!-- <div class="flex items-center flex-1 min-w-0">
                    <div class="flex items-center flex-1 w-full min-w-0"> -->
                <div
                    class="
                        w-1/3
                        tracking-wider
                        sm:text-base
                        lg:text-sm lg:w-1/6
                        text-neutral-800
                        xl:text-base
                        whitespace-nowrap
                    "
                >
                    {{ executionTimestamp }}
                </div>
                <div class="flex justify-center w-1/3 md:w-1/6">
                    <!-- xl:w-1/12 -->
                    <div
                        class="
                            inline-flex
                            items-center
                            justify-start
                            px-2.5
                            py-0.5
                            rounded-md
                            text-sm
                            font-medium
                            capitalize
                        "
                        :class="bgColour"
                    >
                        {{ execution.status }}
                    </div>
                </div>
                <div class="hidden text-sm md:w-1/3 lg:w-1/2 md:flex md:flex-col text-neutral-800 md:text-base">
                    <span class="truncate" :title="modifiedMessage">
                        {{ modifiedMessage }}
                    </span>
                </div>
                <!-- </div>
                </div> -->

                <div class="flex flex-col items-end w-1/3 md:w-1/6">
                    <div class="flex flex-row items-center">
                        <div v-if="execution.status === 'queued'">
                            <confirm-button title="Cancel this execution" class="my-auto">
                                <template #default>
                                    <svg
                                        class="w-5 h-5 cursor-pointer text-neutral-500 hover:text-orange-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                </template>
                                <template #confirm>
                                    <div
                                        class="my-auto text-orange-600 cursor-pointer hover:text-orange-800"
                                        title="Click again to confirm cancelation"
                                        @click.stop="emit('cancel-execution', execution.id)"
                                    >
                                        <svg
                                            class="w-5 h-5 my-auto"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            ></path>
                                        </svg>
                                    </div>
                                </template>
                            </confirm-button>
                        </div>
                        <div v-else class="flex flex-row space-x-1">
                            <svg
                                v-if="lastCompletedExecution === execution.id"
                                class="w-5 h-5 cursor-pointer text-neutral-500 hover:text-purple-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>See results visualization</title>
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                ></path>
                            </svg>
                        </div>
                        <span class="ml-6">
                            <svg
                                class="w-6 h-6 text-gray-400 transform"
                                :class="{ 'rotate-0': !collapsed, '-rotate-180': collapsed }"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </dt>
        <dd v-if="collapsed" id="faq-0">
            <execution-logs :logs="execution.logs" />
        </dd>
    </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import ExecutionLogs from './ExecutionLogs.vue';
import { ConfirmButton } from '@/app/components';

export default defineComponent({
    name: 'ExecutionOverview',
    components: { ConfirmButton, ExecutionLogs },
    props: {
        execution: {
            type: Object,
            required: true,
        },
        collapsedExecution: {
            type: [String, Number],
            default: null,
        },
        lastCompletedExecution: {
            type: [String, Number],
            default: null,
        },
        expandExecution: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const collapsed = ref(false);

        const bgColour = computed(() => {
            switch (props.execution.status) {
                case 'completed':
                    return 'bg-green-200 text-green-800';
                case 'failed':
                    return 'bg-red-200 text-red-800';
                case 'pending':
                    return 'bg-orange-200 text-orange-800';
                case 'running':
                    return 'bg-blue-200 text-blue-800';
                case 'cancelled':
                    return 'bg-yellow-200 text-yellow-800';
                default:
                    return 'bg-neutral-200 text-neutral-800';
                // do nothing
            }
        });

        const executionTimestamp = computed(() => {
            return `${dayjs(props.execution.createdAt).format('DD/MM/YYYY')} at ${dayjs(
                props.execution.createdAt,
            ).format('HH:mm')} UTC`;
        });

        const setCollapsed = () => {
            if (props.collapsedExecution === props.execution.id) {
                collapsed.value = false;
                emit('set-collapsed-execution', null);
            }
            if (!props.collapsedExecution || props.collapsedExecution !== props.execution.id) {
                emit('set-collapsed-execution', props.execution.id);
            }
        };

        const modifiedMessage = computed(() => {
            switch (props.execution.status) {
                case 'completed':
                    return 'All tasks have been executed successfuly';
                case 'failed':
                    return 'The execution has failed to execute';
                case 'queued':
                    return 'The execution is in queue';
                case 'cancelled':
                    return 'The execution has been cancelled';
                case 'running':
                    return 'The execution is running';
                default:
                    return '';
            }
        });

        watch(
            () => [props.collapsedExecution, props.execution],
            () => {
                if (props.collapsedExecution) {
                    collapsed.value = props.collapsedExecution === props.execution.id;
                }
            },
        );

        // const setCollapsed = () => {
        //     console.log(props.collapsedExecution);
        //     console.log(props.execution.id);
        //     if (props.collapsedExecution === props.execution.id) {
        //         collapsed.value = false;
        //         emit('set-collapsed-execution', null);
        //     }
        //     if (!props.collapsedExecution) {
        //         collapsed.value = true;
        //         emit('set-collapsed-execution', props.execution.id);
        //     }
        // };

        const executionStatus = computed(() => {
            if (props.expandExecution && props.expandExecution.status) {
                const status = props.expandExecution.status.split('.'); // e.g. dcj.service.failed
                if (status[status.length - 1] === 'completed') {
                    return 'bg-primary-100';
                }
                return 'bg-red-100';
            }
            return '';
        });

        return { collapsed, bgColour, executionTimestamp, setCollapsed, modifiedMessage, emit, executionStatus };
    },
});
</script>
