import { secured } from '@/app/config/axios';
// import { AxiosRequestConfig } from 'axios';
import { CreateScheduleDTO, UpdateScheduleDTO } from '../dto';

const endpoint = '/schedule';
// const endpoint = '/api/schedule';

export default {
    // getSchedules: (uid: string | number) =>
    //     ({ method: 'GET', url: `${endpoint}/Workflow/${uid}` } as AxiosRequestConfig),
    // create: (data: CreateScheduleDTO) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    // update: (uid: string, data: UpdateScheduleDTO) =>
    //     ({ method: 'PUT', url: `${endpoint}/${uid}`, data } as AxiosRequestConfig),
    // delete: (uid: string) => ({ method: 'DELETE', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    // activate: (uid: string) => ({ method: 'POST', url: `${endpoint}/${uid}/activate` } as AxiosRequestConfig),
    // deactivate: (uid: string) => ({ method: 'POST', url: `${endpoint}/${uid}/deactivate` } as AxiosRequestConfig),
    getSchedules: (uid: string | number) => secured.get(`${endpoint}/Workflow/${uid}`),
    create: (data: CreateScheduleDTO) => secured.post(`${endpoint}`, data),
    update: (uid: string, data: UpdateScheduleDTO) => secured.put(`${endpoint}/${uid}`, data),
    delete: (uid: string) => secured.delete(`${endpoint}/${uid}`),
    activate: (uid: string) => secured.post(`${endpoint}/${uid}/activate`),
    deactivate: (uid: string) => secured.post(`${endpoint}/${uid}/deactivate`),
};
