import { AxiosRequestConfig } from 'axios';
import { Task } from '../types';

const endpoint = '/api/task';

export default {
    get: (uid: string) => ({ method: 'GET', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    update: (task: Task) => ({ method: 'PUT', url: `${endpoint}/${task.id}`, data: task } as AxiosRequestConfig),
    delete: (uid: string) => ({ method: 'DELETE', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
};
