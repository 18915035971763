































import * as R from 'ramda';
import { defineComponent, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import { ExecutionLog } from '../../types';

export default defineComponent({
    name: 'ExecutionLogs',
    props: {
        logs: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const textColour = (type: string) => {
            switch (type) {
                case 'info':
                    return 'text-primary-300';
                case 'error':
                    return 'text-red-300';
                default:
                    return 'text-neutral-300';
            }
        };

        const modifiedTimestamp = (timestamp: any) => {
            return `${dayjs(timestamp).format('DD/MM/YYYY')} at ${dayjs(timestamp).format('HH:mm')} UTC`;
        };

        const sortedLogs = computed(() => {
            const clonedLogs = R.clone(props.logs) as ExecutionLog[];
            const ascSort = R.sortWith([R.ascend(R.prop('timestamp'))]);
            return ascSort(clonedLogs);
        });

        return { textColour, modifiedTimestamp, sortedLogs };
    },
});
