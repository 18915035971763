














































































































import * as R from 'ramda';
import { defineComponent, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import { ExecutionLog } from '../../types';

export default defineComponent({
    name: 'ExecutionHistoryStatistics',
    components: {},
    props: { executions: { type: Array, required: true } },
    setup(props) {
        const totalExecutions = computed(() => props.executions.length);

        const successfulExecutions = computed(
            () => props.executions.filter((e: any) => e.status === 'completed').length,
        );

        const failedExecutions = computed(() => props.executions.filter((e: any) => e.status === 'failed').length);

        const convertMilliseconds = (milliseconds: number) => {
            const totalSeconds = Math.floor(milliseconds / 1000);
            const totalMinutes = Math.floor(totalSeconds / 60);
            const totalHours = Math.floor(totalMinutes / 60);
            const days = Math.floor(totalHours / 24);

            const seconds = totalSeconds % 60;
            const minutes = totalMinutes % 60;
            const hours = totalHours % 24;

            return { d: days, h: hours, m: minutes, s: seconds };
        };

        const convertTimeToModifiedText = (time: any) => {
            if (time.d) {
                return `${time.d / 24 + time.h} hours and ${time.m + (time.s > 30 ? time.s : 0)}`;
            }
            if (time.h) {
                return `${time.h} hours and ${time.m + (time.s > 30 ? time.s : 0)} min`;
            }
            if (time.m) {
                return `${time.m + (time.s > 30 ? time.s : 0)} min`;
            }
            return `${time.s > 30 ? time.s : 0} sec`;
        };

        const avgExecutionTime = computed(() => {
            const executionsWithLogs: any = [];
            const clonedExecutions = R.clone(props.executions);
            clonedExecutions.forEach((e: any) => {
                if (e.status === 'completed' && e.logs.length > 1) {
                    const ascSort = R.sortWith([R.ascend(R.prop('timestamp'))]);
                    const ascLogs = ascSort(e.logs as ExecutionLog[]);
                    executionsWithLogs.push(
                        dayjs(ascLogs[ascLogs.length - 1].timestamp).diff(dayjs(ascLogs[0].timestamp)),
                    );
                }
            });
            if (executionsWithLogs.length) {
                const convertedMilliseconds = convertMilliseconds(
                    R.sum(executionsWithLogs) / executionsWithLogs.length,
                );
                return convertTimeToModifiedText(convertedMilliseconds);
            }
            return 'N/A';
        });

        return { totalExecutions, successfulExecutions, failedExecutions, avgExecutionTime };
    },
});
