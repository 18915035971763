















import { defineComponent, ref } from '@vue/composition-api';
import ExecutionOverview from './ExecutionOverview.vue';

export default defineComponent({
    name: 'Executions',
    components: { ExecutionOverview },
    props: {
        executions: {
            type: Array,
            required: true,
        },
        lastCompletedExecution: {
            type: [String, Number],
            default: null,
        },
        expandExecution: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const collapsedExecution = ref<string | number>(props.expandExecution ? props.expandExecution.id : null);
        const setCollapsedExecution = (execution: string | number) => {
            collapsedExecution.value = execution;
        };
        const cancelExecution = (uid: string | number) => {
            emit('cancel-execution', uid);
        };
        return { setCollapsedExecution, collapsedExecution, cancelExecution };
    },
});
