import { useAxios } from '@vue-composable/axios';
import { computed, ref, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { useQuery, useResult, useErrors } from '@/app/composable';
import { ExecutionSortingOption } from '../constants';
import GET_EXECUTIONS from '../graphql/executions.graphql';
import { Execution } from '../types';

export function useExecutions(
    workflowId: string | number,
    // page: Ref<number>,
    pageSize: number,
    sortBy: Ref<ExecutionSortingOption>,
    incomingPayload?: any,
) {
    const { checkGQLAuthentication } = useErrors();
    const apolloRunner = useQuery(
        GET_EXECUTIONS,
        { workflowId, limit: 0, relations: ['logs'] },
        { fetchPolicy: 'no-cache' },
    );
    const axiosRunner = useAxios();
    apolloRunner.onError(checkGQLAuthentication);

    let executionIdFromNotification = incomingPayload && 'id' in incomingPayload ? incomingPayload.id : null;

    const currentPage = ref<number>(1);

    const executions = ref<Execution[]>([]);
    apolloRunner.onResult(() => {
        executions.value = useResult(apolloRunner.result, null, (data: any) => data.executions).value as Execution[];
    });

    const filteredExecutions = computed(() => {
        const executionsClone = R.clone(executions.value);

        const sortCriteria: any[] = [];
        sortBy.value.fieldPaths.forEach((path: any[]) => {
            sortCriteria.push(sortBy.value.asc ? R.ascend(R.path(path)) : R.descend(R.path(path)));
        });

        const filtered = R.sortWith(sortCriteria)(executionsClone) as Execution[];
        // show correct execution when incoming notification payload + put currentPage here instead of ExecutionHistory
        if (executionIdFromNotification) {
            const idx = R.findIndex(R.propEq('id', executionIdFromNotification))(filtered);
            if (idx > pageSize) {
                currentPage.value = Math.ceil(idx / pageSize);
                executionIdFromNotification = null;
            }
        }

        return filtered;
    });

    const visibleExecutions = computed(() => {
        return filteredExecutions.value.slice(
            (currentPage.value - 1) * pageSize,
            (currentPage.value - 1) * pageSize + pageSize,
        );
    });

    const errors = computed(() => {
        const errorsList = [];
        if (axiosRunner.error.value) {
            errorsList.push(axiosRunner.error.value.message);
        }

        if (apolloRunner.error.value) {
            errorsList.push(apolloRunner.error.value.message);
        }

        return errorsList;
    });

    const loading = computed(() => axiosRunner.loading.value || apolloRunner.loading.value);

    const { refetch } = apolloRunner;
    const { exec } = axiosRunner;

    return { visibleExecutions, executions, filteredExecutions, currentPage, errors, loading, refetch, exec };
}
