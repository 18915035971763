













































































import dayjs from 'dayjs';
import { defineComponent, ref, computed, onBeforeUnmount } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { useSSE } from '@/app/composable';
import { SvgImage, Pagination, Scrollbar, TwSelect } from '@/app/components/';
import { SseQueue } from '@/app/constants';
import ExecutionHistoryStatistics from './ExecutionHistoryStatistics.vue';
import Executions from './Executions.vue';
import { EventMessage } from '../../types';
import { WorkflowAPI, ExecutionAPI } from '../../api';
import { useExecutions } from '../../composable/execution';
import {
    ExecutionSortingOption,
    ExecutionStatus,
    ExecutionType,
    MessageType,
} from '@/modules/workflow-designer/constants';

export default defineComponent({
    name: 'ExecutionHistory',
    components: {
        OrbitSpinner,
        SvgImage,
        Pagination,
        Scrollbar,
        TwSelect,
        ExecutionHistoryStatistics,
        Executions,
    },
    props: {
        workflowId: {
            type: [String, Number],
            required: true,
        },
    },
    setup(props, { root }) {
        const sortingOptions = ExecutionSortingOption.all();
        const fromNotification = ref(root.$route.params ?? null);

        const sortBy = ref<string>(sortingOptions[0].label);
        // const currentPage = ref<number>(1);
        const searchRef = ref<any>(null);
        const pageSize = 10;

        const sortByOption = computed(
            (): ExecutionSortingOption => ExecutionSortingOption.findByLabel(sortBy.value) as ExecutionSortingOption,
        );

        const { visibleExecutions, executions, filteredExecutions, currentPage, errors, loading, refetch, exec } =
            useExecutions(props.workflowId, pageSize, sortByOption, root.$route.params ?? null);

        const lastCompletedExecution = computed(() => {
            const completedExecutions: any = filteredExecutions.value.filter((e: any) => e.status === 'completed');
            const sortedCompletedExecutions = completedExecutions.sort((a: any, b: any) =>
                Math.max(...a.logs.map((l: any) => dayjs.utc(l.timestamp))) <
                Math.max(...b.logs.map((l: any) => dayjs.utc(l.timestamp)))
                    ? 1
                    : -1,
            );

            if (sortedCompletedExecutions.length) {
                return sortedCompletedExecutions[0].id;
            }
            return null;
        });

        const workflow = ref(null);
        const loadWorkflow = () => {
            exec(WorkflowAPI.getWorkflow(props.workflowId)).then((res: any) => {
                workflow.value = res.data;
            });
        };

        const cancelExecution = (uid: string | number) => {
            exec(ExecutionAPI.cancel(uid))
                .then(() => {
                    (root as any).$toastr.s('Execution has been cancelled successfully', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.s('Execution has failed to be cancelled', 'Error');
                });
        };

        loadWorkflow();

        refetch();

        const { initialise: initSSE, destroy: destroySSE } = useSSE();

        const onMessage = (data: EventMessage) => {
            if (data.type === MessageType.Status && data.body.executionType === ExecutionType.Normal) {
                const toBeUpdatedExecution = executions.value.filter((e: any) => e.id === data.executionId)[0];
                toBeUpdatedExecution.status = data.body.status as ExecutionStatus;
            } else if (data.type === MessageType.Log) {
                const toBeUpdatedExecution: any = executions.value.filter((e: any) => e.id === data.executionId)[0];

                if (toBeUpdatedExecution && toBeUpdatedExecution.logs) {
                    toBeUpdatedExecution.logs.push({
                        level: data.body.level,
                        timestamp: dayjs.utc(data.body.timestamp),
                        message: data.body.message,
                    });
                }
            }
        };
        const onError = (data: any) => {
            console.log(data);
        };

        initSSE(`/api/workflow/${props.workflowId}/sse`, SseQueue.Workflow, onMessage, onError);
        onBeforeUnmount(() => {
            destroySSE();
        });

        return {
            loading,
            errors,
            searchRef,
            currentPage,
            pageSize,
            executions,
            filteredExecutions,
            sortingOptions,
            sortBy,
            visibleExecutions,
            workflow,
            lastCompletedExecution,
            cancelExecution,
            fromNotification,
        };
    },
});
